import axios from "axios";
import Config from "./config";

export const AgentcheckTokenValidity = async () => {
  try {
    const token = localStorage.getItem("token-agent");
    if (!token) {
      return false;
    }

    const response = await axios.post(
      `${Config.baseURL}/check-agent-token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response.data.decoded);

    return response.data.valid;
  } catch (error) {
    return false;
  }
};
