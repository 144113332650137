import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import AgentLogin from "./pages/AgentLogin";
import AgentProfile from "./pages/AgentProfile";
import AgentPrivateRoutes from "./utils/AgentPrivateRoutes";
import DisponibilityAgent from "./pages/DisponibilityAgent";

function App() {
  return (
    <Router>
      <MainLayout />
    </Router>
  );
}

function MainLayout() {
  const location = useLocation();
  const noSidebarRoutes = ["/agent-login", "/"];

  return (
    <div className="flex">
  
        <Routes>
          <Route element={<AgentPrivateRoutes />}>
            <Route element={<AgentProfile />} path="/profile" />
            <Route element={<DisponibilityAgent />} path="/disponibility" />
          </Route>

          <Route element={<AgentLogin />} path="/" />
         </Routes>
      </div>
   );
}

export default App;
