import { useState, useEffect } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { AgentcheckTokenValidity } from './AgentTokenValidity';

const AgentPrivateRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchTokenValidity = async () => {
      const isValid = await AgentcheckTokenValidity();
      setIsAuthenticated(isValid);
      if (!isValid) {
        navigate('/');  
      }
    };

    fetchTokenValidity();
  }, []);

  return isAuthenticated ? <Outlet /> : <Link to="/" />;
};

export default AgentPrivateRoutes;
